import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../src_front/js/mixpannel/globalEventProperties';
import mixpanel from 'mixpanel-browser';

export const initEmailAutocompleteDropdown = () => {
    // VARS
    const emailInputs = document.querySelectorAll('[data-autocomplete-email-input]');
    const autocompleteDropdownItems = document.querySelectorAll('[data-autocomplete-email-list-value]');
    const classVisible = 'visible';
    const classSelected = 'selected';
    let selectedIndex = -1;

    // LISTENERS
    [...emailInputs].forEach((emailInput) => {
        emailInput.addEventListener('keyup', handleOnKeyup, false);
        emailInput.addEventListener('keydown', handleOnKeydown, false);
        emailInput.addEventListener('focus', handleOnFocus, false);
        emailInput.addEventListener('blur', hideMailersLists, false);
    });

    document.addEventListener('click', function (event) {
        if (!event.target.closest('[data-autocomplete-email]')) {
            hideMailersLists();
        }
    });

    [...autocompleteDropdownItems].forEach((item) => {
        item.addEventListener('mousedown', handleOnClickAutocompleteDropdownItem);
    });

    // HANDLERS
    function handleOnKeyup() {
        this.value.trim().length ? showMailersList(this) : hideMailersLists();
    }

    function handleOnKeydown(event) {
        navigateDropdown(this, event);
    }

    function handleOnFocus() {
        if (this.value.trim().length) showMailersList(this);
    }

    function handleOnClickAutocompleteDropdownItem() {
        setAutocompleteValueToEmail(this);
        hideMailersLists();
    }

    // FUNCTIONS
    function showMailersList(emailInput) {
        const mailersListElem = emailInput
            .closest('[data-autocomplete-email]')
            .querySelector('[data-autocomplete-email-list]');
        const listItems = mailersListElem.querySelectorAll('[data-autocomplete-email-list-value]');

        [...listItems].forEach((item) => {
            const mailer = item.dataset.autocompleteEmailListValue;
            const inputValue = emailInput.value.trim().toLowerCase();
            const emailName = inputValue.split('@')[0];
            const autocompleteEmail = emailName + mailer;

            if (autocompleteEmail === inputValue) {
                item.classList.remove(classVisible);
            } else if (autocompleteEmail.includes(inputValue)) {
                item.textContent = autocompleteEmail;
                item.classList.add(classVisible);
            } else {
                item.textContent = mailer;
                item.classList.remove(classVisible);
            }
        });

        if (mailersListElem.querySelector('.visible[data-autocomplete-email-list-value]')) {
            mailersListElem.classList.add(classVisible);
        } else {
            mailersListElem.classList.remove(classVisible);
        }
    }

    function hideMailersLists() {
        const mailersLists = document.querySelectorAll('[data-autocomplete-email-list]');

        [...mailersLists].forEach((mailersList) => {
            mailersList.classList.remove(classVisible);
        });
    }

    function navigateDropdown(input, event) {
        const dropdown = input.closest('[data-autocomplete-email]').querySelector('[data-autocomplete-email-list]');
        const items = dropdown.querySelectorAll('.visible[data-autocomplete-email-list-value]');

        if (dropdown.classList.contains(classVisible)) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                clearSelection();
                selectedIndex = selectedIndex < items.length - 1 ? selectedIndex + 1 : 0;
                items[selectedIndex].classList.add(classSelected);
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                clearSelection();
                selectedIndex = selectedIndex > 0 ? selectedIndex - 1 : items.length - 1;
                items[selectedIndex].classList.add(classSelected);
            } else if (event.key === 'Enter') {
                event.preventDefault();
                clearSelection();
                setAutocompleteValueToEmail(items[selectedIndex]);
                hideMailersLists();
                selectedIndex = -1;
            }
        }
    }

    function setAutocompleteValueToEmail(item) {
        const emailInput = item.closest('[data-autocomplete-email]').querySelector('[data-autocomplete-email-input]');
        emailInput.value = item.textContent;
    }

    function clearSelection() {
        const items = document.querySelectorAll('[data-autocomplete-email-list-value]');

        [...items].forEach((item) => {
            item.classList.remove(classSelected);
        });
    }
};
