export function generateGl() {
    const getCookiebyName = function (name) {
        var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
        return !!pair ? pair[1].match(/GA1\.[0-9]\.(.+)/)[1] : undefined;
    };

    return window.google_tag_data?.glBridge?.generate({
        _ga: getCookiebyName('_ga'),
        _ga_CSF8YKE9Y3: getCookiebyName('_gid'),
    });
}
