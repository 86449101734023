export const initHashEmail = () => {
    // Vars
    const urlQueries = window.location.search;
    const searchParams = new URLSearchParams(urlQueries);
    const email = searchParams.get('email');
    const emailFields = document.querySelectorAll('[data-email-hash]');
    const googleAuthEmail = document.querySelector('[data-goolge-auth]');
    // INITS
    if (email) {
        localStorage.setItem('user-email', email);
    }

    if (googleAuthEmail) {
        localStorage.setItem('user-email', googleAuthEmail.dataset.goolgeAuth);
    }

    if (emailFields.length) {
        [...emailFields].forEach((emailField) => {
            emailField.value = localStorage.getItem('user-email');
        });
    }
};
