import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initHashEmail } from './components/initHashEmail';
import { initScrollLocker } from './tools/scrollLocker';
import { initHashMessage } from './components/initHashMessage';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initAutoresizeTextarea } from './components/initAutoresizeTextarea';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

function handleOnLoadPage() {
    initAutoresizeTextarea();
    initLazyLoadFonts();
    initScrollLocker();
    initForms();
    initJivosite();
    initHashEmail();
    initHashMessage();
    initEmailAutocompleteDropdown();
}
