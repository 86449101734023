import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import { trackMixpanelBeforeRedirectToCheckout } from '../../helper-function/trackMixpanelBeforeRedirectToCheckout';

export function initCreateAccountEmail() {
    const forms = document.querySelectorAll('[data-create-account-form]');

    [...forms].forEach((form) => {
        initForm(form);
    });

    headerButtonSubmit();
}

function initForm(form) {
    const userEmail = form.querySelector('[data-create-account-email]');
    const agreeCheckbox = form.querySelector('[data-create-account-checkbox]');
    const fieldsSelector = {
        userEmail: '[data-create-account-email]',
        agreeCheckbox: '[data-create-account-checkbox]',
    };
    const classShowErrorMessage = 'is-error';
    let isValidForm = true;

    // INITS
    if (form.dataset.createAccountForm === 'email-page') {
        userEmail.focus();
    }

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    agreeCheckbox?.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateUserEmail();
        if (agreeCheckbox) validateAgreeCheckbox();

        if (!isValidForm) {
            isValidForm = true;
        } else {
            if (form.dataset.createAccountForm === 'email-page') {
                if (Boolean(window.fbq)) {
                    fbq('track', 'CompleteRegistration');
                }

                if (Boolean(window.ttq)) {
                    ttq.instance('CHVE5JBC77U8RIVT99JG').track('CompleteRegistration');
                }
            }

            if (form.hasAttribute('data-is-end-funnel')) {
                redirect(this, trackMixpanelBeforeRedirectToCheckout);
                return;
            }

            if (window.pageLoader) {
                window.pageLoader.show();
            }

            if (form.dataset.createAccountForm === 'submit') {
                sessionStorage.setItem('email', userEmail.value.trim());
            }

            this.submit();
        }
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnChangeAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);
    }

    // METHODS & FUNCTIONS
    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);

        if (agreeCheckbox.checked) {
            hideErrorMessage(fieldsSelector.agreeCheckbox);
        } else {
            showErrorMessage(fieldsSelector.agreeCheckbox, 'checkbox');
            isValidForm = false;
        }
    }

    function hideErrorMessage(selector) {
        const field = form.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = form.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }
}

function headerButtonSubmit() {
    const additionalButtons = document.querySelectorAll('[data-create-account-submit-header]');
    if (!additionalButtons) return;

    additionalButtons.forEach(function (additionalButton) {
        additionalButton.addEventListener('click', () => {
            const form = document.querySelector('[data-create-account-form]');
            if (!form) return;

            form.requestSubmit();
        });
    });
}
