export const initHashMessage = () => {
    // Vars
    const urlQueries = window.location.search;
    const searchParams = new URLSearchParams(urlQueries);
    const text = searchParams.get('message');
    const textField = document.querySelector('[data-message-hash]');

    // INITS
    if (text) {
        localStorage.setItem('user-message', text);
    }

    if (textField) {
        textField.value = localStorage.getItem('user-message');
    }
};
