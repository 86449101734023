import { initCreateAccountEmail } from './forms/initCreateAccountEmail';
import { initContactFormDescriptor } from './forms/initContactFormDescriptor';
import { initContactForm } from './forms/initContactForm';
import { initAppendHiddenInputsToPlanForms } from '../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';

export const initForms = () => {
    initAppendHiddenInputsToPlanForms();
    initCreateAccountEmail();
    initContactForm();
    initContactFormDescriptor();
};
