import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';

export function trackMixpanelBeforeRedirectToCheckout() {
    const mixpanelKey = document.querySelector('[data-mixpannel-key]')?.dataset.mixpannelKey;
    if (!mixpanelKey) return;
    const props = cloneDeep(globalEventProperties);
    mixpanel.track('last_page_before_checkout', props);
}
