import JustValidate from 'just-validate/dist/js/just-validate.min';
import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';
import { Modal } from '../modal/Modal';
import { globalEventProperties } from '../../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';

export const initContactFormDescriptor = () => {
    const formSelector = '[data-contact-us-form-descriptor]';
    const form = document.querySelector(`${formSelector}`);
    if (!form) return;

    const mixpanelKey = document.querySelector('[data-mixpannel-key]')?.dataset.mixpannelKey;
    const modal = new Modal();

    const formAction = new window.JustValidate(`${formSelector}`, {
        rules: {
            email: {
                required: true,
                email: true,
                strength: {
                    custom: '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,4}$',
                },
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        messages: {
            email: 'Field filled incorrectly',
        },

        submitHandler: function (form, values, ajax) {
            const email = form.querySelector('[data-mixpanel-email]');

            if (mixpanelKey) {
                const props = cloneDeep(globalEventProperties);

                if (email) {
                    props.form_value = email.value.trim();

                    mixpanel.people.set({
                        $email: email.value.trim(),
                    });
                }

                mixpanel.track('button_click', props);
            }

            if (isReCaptchaValid()) {
                removeRecaptchaError(`${formSelector}`);
            } else {
                setRecaptchaError(`${formSelector}`);
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    modal.open('successfully-sent');
                },
                error: function (response) {
                    modal.open('error-message');
                },
            });
        },

        invalidFormCallback: function (errors) {
            const props = cloneDeep(globalEventProperties);
            const email = form.querySelector('[data-mixpanel-email]');
            let errorText = '';

            if (mixpanelKey) {
                props.button_name = 'Submit';
            }

            for (let error in errors) {
                errorText = `${errorText ? errorText + ';' : ''} ${errors[error].message}`;
            }

            if (!isReCaptchaValid()) {
                errorText = `${errorText ? errorText + ';' : ''} 'The field is required'`;
            }

            if (mixpanelKey) {
                props.form_validation = errorText;

                if (email) {
                    props.form_value = email.value.trim();

                    mixpanel.people.set({
                        $email: email.value.trim(),
                    });
                }

                mixpanel.track('button_click', props);
            }

            if (errors && !isReCaptchaValid()) {
                setRecaptchaError(`${formSelector}`);
            }
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
        removeRecaptchaError(`${formSelector}`);
    }
};
