// Если вешать сбытие на документ, то проверяет по селектору
// или был клик на нужный элемент или по его потомку
// Возвращает элемент по заданному селектору

// Метод elem.closest(css) ищет ближайшего по иерархии предка,
// соответствующему данному CSS-селектору. Сам элемент также включён в поиск.

// event - класический обьект события, надо передавать
// selector - строка селектор, например класс - '.selector';
function getTarget(event, selector) {
    if (event.target.closest(selector)) {
        return event.target.closest(selector);
    }
}

export { getTarget };
